//
//
//
//
import * as dayjs from 'dayjs';
import * as isToday from 'dayjs/plugin/isToday';
import * as isYesterday from 'dayjs/plugin/isYesterday';
import * as updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(updateLocale);
export default {
  name: 'date-formatter',
  props: {
    date: {
      type: [String, Date]
    },
    format: {
      type: String,
      default: 'DD MMMM YYYY, HH:mm'
    },
    timeFormat: {
      type: String,
      default: 'HH:mm'
    }
  },
  computed: {
    formattedDate: function formattedDate() {
      var date = this.date || new Date();
      var dayjsDate = dayjs(date);
      if (dayjsDate.isToday()) return 'Today';
      if (dayjsDate.isYesterday()) return 'Yesterday';
      return dayjsDate.format(this.format);
    }
  }
};